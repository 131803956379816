.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.kapeammaksi-kuinRuutu-eka {
  max-width: 70%;
  padding-bottom: 0;
  margin-bottom: 0;
}

.kapeammaksi-kuinRuutu {
  max-width: 70%;
}

.kapeammaksi-kuinRuutu-vika {
  color: #07a832;
  max-width: 60%;
  padding-bottom: 0;
  margin-bottom: 0;
}

footer {
  margin-top: 3em;
  margin-bottom: 0;
}